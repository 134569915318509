<template>
    <Button label="Kembali" icon="pi pi-angle-left" class="p-button-link p-button-rounded p-button-success mb-2" @click="this.$router.go(-1)"/>
	<Toast />
	<ConfirmDialog></ConfirmDialog>
	<div class="grid fadein animation-duration-300" :key="componentKey">
		<div class="col-12">
			<div class="card">
				<h5>Daftar Amil</h5>
				<DataTable :value="listAmil" :paginator="true" class="p-datatable-gridlines" :rows="10" :rowsPerPageOptions="[10,20,30]" dataKey="id" :rowHover="true" 
							v-model:filters="filters1" filterDisplay="row" :loading="loading" :filters="filters1" responsiveLayout="scroll" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Menampilkan amil {first}-{last} dari {totalRecords} Amil"
							:globalFilterFields="['nama', 'role', 'email', 'status']" stripedRows >
					
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Reset Pencarian" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="Cari" style="width: 100%"/>
                            </span>
                        </div>
                    </template>
                    <template #empty>
                        Daftar amil tidak ditemukan...
                    </template>
                    <template #loading>
                        Memuat daftar amil. Mohon menunggu...
                    </template>
                    <Column field="nama" header="Nama" style="min-width:12rem" :sortable="true">
                        <template #body="{data}">
                            {{data.nama}}
                        </template>
						<template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Cari nama" @input="filterCallback()"/>
                        </template>
                    </Column>
					<Column field="email" header="Email" style="min-width:12rem" :sortable="true">
                        <template #body="{data}">
                            {{data.email}}
                        </template>
						<template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Cari email" @input="filterCallback()"/>
                        </template>
                    </Column>
                    <Column field="role" header="Role" style="min-width:12rem" :sortable="true">
                        <template #body="{data}">
                            {{data.role}}
                        </template>
						<template #filter="{filterModel,filterCallback}">
							<Dropdown v-model="filterModel.value" @change="filterCallback()" :options="roles" placeholder="Role" class="p-column-filter" :showClear="true">
								<template #value="slotProps">
									<span v-if="slotProps.value">{{slotProps.value}}</span>
									<span v-else>{{slotProps.placeholder}}</span>
								</template>
								<template #option="slotProps">
									<span>{{slotProps.option}}</span>
								</template>
							</Dropdown>
						</template>
                    </Column>
					<Column field="status" header="Status" bodyClass="text-center" style="min-width:8rem" :sortable="true" :showFilterMenu="false" >
                        <template #body="{data}">
							<span :class="'customer-badge status-' + [data.status === 'Aktif' ? 'qualified' : 'unqualified']">{{data.status}}</span>
                        </template>
                        <template #filter="{filterModel,filterCallback}">
							<Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" placeholder="Status" class="p-column-filter" :showClear="true">
								<template #value="slotProps">
									<span :class="'customer-badge status-' + [slotProps.value === 'Aktif' ? 'qualified' : 'unqualified']" v-if="slotProps.value">{{slotProps.value}}</span>
									<span v-else>{{slotProps.placeholder}}</span>
								</template>
								<template #option="slotProps">
									<span :class="'customer-badge status-' + [slotProps.option === 'Aktif' ? 'qualified' : 'unqualified']">{{slotProps.option}}</span>
								</template>
							</Dropdown>
						</template>
                    </Column>
					<Column header="Aktivasi" v-if="(this.$store.state.user.role == 'Super Admin')">
						<template #body="{data}">
							<InputSwitch trueValue="Aktif" falseValue="Non-Aktif" v-model="data.status" @update:modelValue="updateStatus(data.id, data.status, data.nama)"/>
							<p v-if="!data.status">Aktivasi</p>
							<p v-if="data.status">Nonaktivasi</p>							
						</template>
					</Column>
					<Column header="Hapus" v-if="(this.$store.state.user.role == 'Super Admin')">
						<template #body="{data}">
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="destroy(data.id, data.status, data.nama)"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
	import {FilterMatchMode} from 'primevue/api';

	import Amil from '../api/Amil'

	export default {
		name: 'Amil',
        created() {
			this.AmilList = new Amil();
        },
		data() {
			return {
				listAmil: null,
				filters1: {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'nama': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'email': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'role': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'status': {value: null, matchMode: FilterMatchMode.EQUALS},
				},
				loading: false,
				checked: true,
				statuses: [
					'Aktif', 'Non-Aktif'
				],
				roles: [
					'Amil Junior', 'Amil Senior', 'Admin'
				],
				componentKey: 0,
			}
		},
		mounted() {
			this.loading = true;
			this.AmilList.getList()
                    .then(response => {
                        this.listAmil = response.data
						this.loading = false;
                    })
                    .catch(error => {
						this.networkError()
                    })
		},
		methods: {
			clearFilter1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'nama': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'email': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'role': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'status': {value: null, matchMode: FilterMatchMode.EQUALS},
				}
			},
			updateStatus(id, status, nama) {
				if (status == "Aktif") {
					status = false
				} else if (status == "Non-Aktif") {
					status = true
				}
                this.AmilList.updateStatus(id, status)
                    .then(async response => {
						this.AmilList.getList()
							.then(response => {
								this.listAmil = response.data
								this.loading = false;
							})
							.catch(error => {
								this.networkError()
							})
						if (!status) {
							this.$toast.add({severity:'success', summary: 'Sukses', detail:nama+' berhasil diaktifkan', life: 3000});
						} else {
							this.$toast.add({severity:'warn', summary: 'Sukses', detail:nama+' berhasil dinonaktifkan', life: 3000});
						}
                    })
                    .catch(error => {
						this.networkError()
                    })
            },
			destroy(id, status, nama) {
				if (status == "Aktif") {
					this.$confirm.require({
						message: 'User yang ingin dihapus masih aktif. Nonaktifkan?',
						header: 'User masih aktif',
						icon: 'pi pi-ban',
						acceptClass: 'p-button-info',
						accept: () => {
							this.AmilList.updateStatus(id, status)
								.then(async response => {
									if (!status) {
										this.$toast.add({severity:'success', summary: 'Sukses', detail:nama+' berhasil diaktifkan', life: 3000});
									} else {
										this.$toast.add({severity:'warn', summary: 'Sukses', detail:nama+' berhasil dinonaktifkan', life: 3000});
									}
									this.AmilList.getList()
										.then(response => {
											this.listAmil = response.data
											this.loading = false;
										})
										.catch(error => {
											this.networkError()
											
										})
								})
								.catch(error => {
									this.networkError()
								})
						},
						reject: () => {}
					})
				} else {
					this.$confirm.require({
						message: 'Data yang telah dihapus tidak dapat dikembalikan.',
						header: 'Yakin hapus data?',
						icon: 'pi pi-exclamation-triangle',
						acceptClass: 'p-button-danger',
						accept: () => {
							this.AmilList.delete(id)
								.then(async response => {
									this.$toast.add({severity:'success', summary: 'Sukses', detail:nama+' berhasil dihapus', life: 3000});
									this.AmilList.getList()
										.then(response => {
											this.listAmil = response.data
											this.loading = false;
										})
										.catch(error => {
											this.networkError()
										})
								})
								.catch(error => {
									this.networkError()
								})
						}
					});
				}
			},
			networkError() {
                this.$confirm.require({
                    message: 'Telah terjadi kesalahan. Coba refresh halaman.',
                    header: 'Error',
                    icon: 'pi pi-exclamation-triangle',
                    blockScroll: true,
                    defaultFocus: "accept",
                    accept: () => {
                        this.$router.go()
                    },
                    reject: () => {
                        this.$confirm.close();
                    }
                });
            },
		}
	}
</script>